import { Box, Typography } from '@mui/material'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { CmsImage } from '../types'

export type MediaBlockProps = {
  strapi_component: string
  mediaContent: CmsImage
  caption: string
}

const BlogMediaBlock = (props: MediaBlockProps) => {
  const { mediaContent, caption } = props

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <GatsbyImage
        image={getImage(mediaContent.localFile) as IGatsbyImageData}
        alt={mediaContent.alternativeText}
        objectFit="contain"
      />
      <Typography variant="c1">{caption}</Typography>
    </Box>
  )
}

export default BlogMediaBlock
