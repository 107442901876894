import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { graphql } from 'gatsby'
import { UserEventType } from '@flock/utils'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import { SnackbarProvider, useTracking } from '@flock/shared-ui'
import BlocksRenderer from '../BlocksRenderer'
import PageWrapper from '../SharedComponents/PageWrapper'
import SectionLayout from '../SharedComponents/SectionLayout'
import { StrapiArticle } from '../types'
import BlogMediaBlock from './BlogMediaBlock'
import BlogShare from './BlogShare'
import BlogTextBlock from './BlogTextBlock'
import RelatedArticles from './RelatedArticles'
import InlineMessageAddress from '../SharedComponents/InlineMessageAddress'
import { WebinarModal } from '../SharedComponents/WebinarModal'

const availableBlocks = {
  'blog.media-block': BlogMediaBlock,
  'blog.text-block': BlogTextBlock,
}

export const Head = (props: BlogPostProps) => {
  const { data } = props
  const { title, description, coverImage } = data.strapiArticle

  return (
    <>
      <title>{title}</title>
      <meta title="title" property="og:title" content={title} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta
        name="image"
        property="og:image"
        // @ts-ignore
        content={coverImage.localFile.url}
      />
    </>
  )
}

type BlogPostProps = {
  data: {
    strapiArticle: StrapiArticle
  }
}

const BlogPost = (props: BlogPostProps) => {
  const { data } = props
  const {
    title,
    slug,
    publishedAt,
    author,
    coverImage,
    contentBlocks,
    relatedArticles,
  } = data.strapiArticle
  const publicationDate = publishedAt ? new Date(publishedAt) : new Date()
  const renderedDate = publicationDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { trackPage } = useTracking()

  useEffect(() => {
    if (slug) {
      trackPage(`blog`, {
        category: 'blog',
        slug,
        actionType: UserEventType.PAGE_VISIT,
      })
    }
  }, [slug, trackPage])

  return (
    <PageWrapper
      title={`Flock | ${title}`}
      trackingName="blog"
      headerBackground="trustBlue.main"
    >
      <SnackbarProvider>
        <SectionLayout name="blog-post-header" backgroundColor="trustBlue.main">
          {!isTablet && <Grid item md={2} />}
          <Grid item xs={12} md={8}>
            <Grid item xs={12} display="flex" flexDirection="column" gap="16px">
              <Typography variant="h2">{title}</Typography>
              <Typography>
                Published {renderedDate}{' '}
                {author?.name && `— Written by ${author.name}`}
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                }}
              />
              <BlogShare slug={slug} />
            </Grid>
            <Grid item xs={12} display="flex">
              <GatsbyImage
                image={getImage(coverImage.localFile) as IGatsbyImageData}
                alt={coverImage.alternativeText}
                loading="eager"
                objectFit="contain"
              />
            </Grid>
            <Grid item xs={12}>
              <BlocksRenderer
                contentBlocks={contentBlocks}
                availableBlocks={availableBlocks}
              />
            </Grid>
            <Box
              sx={{
                '& .MuiBox-root': {
                  margin: 'auto',
                },
              }}
            >
              <InlineMessageAddress
                text="Landlords, retire and minimize taxes."
                variant="button"
                label="Get Started"
              />
              <Grid item xs={12} textAlign="end" pt="16px">
                <WebinarModal />
              </Grid>
            </Box>
            <Grid item xs={12}>
              {relatedArticles?.relatedArticles && (
                <RelatedArticles articles={relatedArticles.relatedArticles} />
              )}
            </Grid>
          </Grid>
          {!isMobile && <Grid item sm={2} />}
        </SectionLayout>
      </SnackbarProvider>
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      publishedAt
      contentBlocks {
        ... on STRAPI__COMPONENT_BLOG_MEDIA_BLOCK {
          strapi_component
          mediaContent {
            alternativeText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
          }
          caption
        }
        ... on STRAPI__COMPONENT_BLOG_TEXT_BLOCK {
          strapi_component
          textContent {
            data {
              textContent
            }
          }
        }
        ... on STRAPI__COMPONENT_GLOBAL_LEAD_GEN_CARD {
          strapi_component
          title
          formHeader
          ctaText
          detail {
            data {
              detail
            }
          }
        }
      }
      coverImage {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
          }
        }
      }
      relatedArticles {
        relatedArticles {
          title
          description
          slug
          publishedAt
          coverImage {
            alternativeText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.77
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
          category {
            name
          }
        }
      }
      author {
        name
        bio {
          data {
            bio
          }
        }
        avatar {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 160
                formats: [WEBP]
                aspectRatio: 1
              )
            }
          }
        }
      }
    }
  }
`

export default BlogPost
