import { Box, Typography } from '@mui/material'
import React from 'react'
import { QuotationLargeIcon } from '../icons/QuotationLargeIcon'

export type QuoteTextProps = {
  text: string
  variant: 'inline' | 'overlay' | 'stories'
  isSmall: boolean
}

const QuoteText = (props: QuoteTextProps) => {
  const { text, variant, isSmall } = props
  if (variant === 'overlay') {
    return (
      <Box>
        <Box position="absolute" sx={{ mt: '-16px', ml: '-8px' }}>
          <QuotationLargeIcon width="47px" height="32px" />
        </Box>
        <Typography variant={isSmall ? 'p3' : 'p1'}>{text}</Typography>
      </Box>
    )
  } else if (variant === 'stories') {
    return (
      <Box display="flex" alignItems="flex-start" gap="32px">
        <Box flexShrink={0}>
          <QuotationLargeIcon width="49px" height="33px" />
        </Box>
        <Typography variant={isSmall ? 'h3m' : 'h3'} color="primary.main">
          {text}
        </Typography>
      </Box>
    )
  } else {
    return (
      <Box display="flex" alignItems="center" gap="16px">
        <Box flexShrink={0}>
          <QuotationLargeIcon
            width={isSmall ? '35px' : '76px'}
            height={isSmall ? '24px' : '52px'}
          />
        </Box>
        <Typography variant={isSmall ? 'c1' : 'p1'}>{text}</Typography>
      </Box>
    )
  }
}

export default QuoteText
