import { TrackingContextProvider } from '@flock/shared-ui'
import React from 'react'
import { ContentBlock } from './types'

const processStrapiComponentName = (name: string) => {
  const periodIndex = name.indexOf('.')
  return name.substring(periodIndex + 1)
}

type ContentBlockRendererProps = {
  availableBlocks: {
    [key: string]: React.FC<any>
  }
  contentBlocks: ContentBlock[]
  extraProps?: { [key: string]: any }
}

/**
 * Utility component that takes a set of blocks and renders them based on
 * an input array of block configurations
 */
const BlocksRenderer = (props: ContentBlockRendererProps) => {
  const { availableBlocks, contentBlocks, extraProps } = props
  return (
    <>
      {contentBlocks.map((blockConfig: ContentBlock) => {
        let additionalProps: any = {}
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        const { strapi_component, experimentConfig, ...componentProps } =
          blockConfig
        if (extraProps && extraProps[strapi_component]) {
          additionalProps = { ...extraProps[strapi_component] }
        }
        const BlockToRender = availableBlocks[strapi_component]
        if (!strapi_component || !BlockToRender) {
          return null
        }
        return (
          <TrackingContextProvider
            name={processStrapiComponentName(strapi_component)}
            initialTrackingProperties={componentProps}
          >
            <BlockToRender {...componentProps} {...additionalProps} />
          </TrackingContextProvider>
        )
      })}
    </>
  )
}

BlocksRenderer.defaultProps = {
  extraProps: {},
}

export default BlocksRenderer
