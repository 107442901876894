import { QuoteText } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useMarkdownToHtml } from '../utils'

export type TextBlockProps = {
  strapi_component: string
  textContent: {
    data: {
      textContent: string
    }
  }
}

const BlogTextBlock = (props: TextBlockProps) => {
  const { textContent } = props

  let quote = ''
  let speaker = ''
  let renderTextContent = textContent.data.textContent
  if (renderTextContent.includes('(quote)')) {
    const quoteMatches = renderTextContent.match(
      /\(quote\)(.|\n)*\(end-quote\)/g
    )
    const rawQuoteHtml = quoteMatches![0]

    quote = rawQuoteHtml.substring(
      rawQuoteHtml.indexOf('(quote)') + 7,
      rawQuoteHtml.indexOf('(end-quote)')
    )

    const quoteSpeakerMatches = renderTextContent.match(
      /\(quote-speaker\)(.|\n)*\(end-quote-speaker\)/g
    )
    const rawSpeakerHtml = quoteSpeakerMatches![0]
    speaker = rawSpeakerHtml.substring(
      rawSpeakerHtml.indexOf('(quote-speaker)') + 15,
      rawSpeakerHtml.indexOf('(end-quote-speaker)')
    )

    renderTextContent = renderTextContent.substring(
      0,
      renderTextContent.indexOf('(quote)')
    )
  }

  const dataToRender = useMarkdownToHtml(renderTextContent)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          sx={{
            '& a': {
              textDecoration: 'none!important',
              color: '#136D42',
            },
            '& h3': {
              fontWeight: 500,
              pt: '16px',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: dataToRender,
          }}
        />
      </Grid>

      {(quote || speaker) && (
        <>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={10}>
            {quote && (
              <Box pb="24px">
                <QuoteText text={quote} variant="stories" isSmall={false} />
              </Box>
            )}
            {speaker && (
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                pb="48px"
              >
                <Typography variant="h4" color="moneyGreen.main">
                  {speaker}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={0} sm={1} />
        </>
      )}
    </Grid>
  )
}

export default BlogTextBlock
