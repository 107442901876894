import { Snackbar, Alert, SnackbarOrigin, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { createContext, useState } from 'react'

type Severity = 'info' | 'warning' | 'success' | 'error'

type SnackbarContextProps = {
  notify: (
    text: string,
    type?: Severity,
    anchor?: SnackbarOrigin,
    newSx?: SxProps<Theme>
  ) => void
}

export const SnackbarContext = createContext({} as SnackbarContextProps)

type SnackbarProviderProps = {
  children: React.ReactNode
}

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [alert, setAlert] = useState('')
  const [severity, setSeverity] = useState<Severity>('info')
  const [open, setOpen] = useState(false)
  const [anchorOrigin, setAnchorOrigin] = useState<SnackbarOrigin>({
    vertical: 'top',
    horizontal: 'right',
  })
  const [sx, setSx] = useState<SxProps<Theme>>({ marginTop: '3rem' })

  const handleClose = (
    _: React.SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const notify = (
    text: string,
    type: Severity = 'info',
    anchor: SnackbarOrigin = { vertical: 'top', horizontal: 'right' },
    newSx: SxProps<Theme> = { marginTop: '3rem' }
  ) => {
    setAlert(text)
    setSeverity(type)
    setOpen(true)
    setAnchorOrigin(anchor)
    setSx(newSx)
  }

  return (
    <SnackbarContext.Provider value={{ notify }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={severity}
          sx={sx}
        >
          {alert}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
