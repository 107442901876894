import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { StrapiArticle } from '../types'
import ArticleCard from './ArticleCard'

type RelatedArticlesProps = {
  articles: StrapiArticle[]
}

const RelatedArticles = (props: RelatedArticlesProps) => {
  const { articles } = props
  if (!articles || !articles.length) {
    return null
  }
  return (
    <Box display="flex" flexDirection="column" gap="16px" pt="48px">
      <Typography variant="h3">Further Reading</Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Grid container spacing={3}>
        {articles.map((article) => (
          <ArticleCard {...article} />
        ))}
      </Grid>
    </Box>
  )
}

export default RelatedArticles
