import { TrackedIconButton } from '@flock/shared-ui'
import { Email, Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { UserEventType } from '@flock/utils'
import React from 'react'
import { track } from '../../utils/analytics'

type BlogShareProps = {
  slug: string
}

const BlogShare = (props: BlogShareProps) => {
  const { slug } = props

  const onFacebook = () => {
    track(`blog.facebookShare`, {
      category: 'blog',
      slug,
      actionType: UserEventType.ICON_BUTTON_CLICK,
    })
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      '_blank'
    )
  }

  const onLinkedin = () => {
    track(`blog.linkedinShare`, {
      category: 'blog',
      slug,
      actionType: UserEventType.ICON_BUTTON_CLICK,
    })
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
      '_blank'
    )
  }

  const onTwitter = () => {
    track(`blog.twitterShare`, {
      category: 'blog',
      slug,
      actionType: UserEventType.ICON_BUTTON_CLICK,
    })
    window.open(
      `https://twitter.com/intent/tweet?url=${window.location.href}`,
      '_blank'
    )
  }

  const onEmail = () => {
    track(`blog.emailShare`, {
      category: 'blog',
      slug,
      actionType: UserEventType.ICON_BUTTON_CLICK,
    })
    window.open(`mailto:?&body=${window.location.href}`)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Typography sx={{ marginRight: '1rem' }}>
        <b>Share this article</b>
      </Typography>
      <TrackedIconButton
        onClick={onFacebook}
        trackingConfig={{ name: 'facebook' }}
      >
        <Facebook />
      </TrackedIconButton>
      <TrackedIconButton
        onClick={onLinkedin}
        trackingConfig={{ name: 'linkedin' }}
      >
        <LinkedIn />
      </TrackedIconButton>
      <TrackedIconButton
        onClick={onTwitter}
        trackingConfig={{ name: 'twitter' }}
      >
        <Twitter />
      </TrackedIconButton>
      <TrackedIconButton onClick={onEmail} trackingConfig={{ name: 'email' }}>
        <Email />
      </TrackedIconButton>
    </Box>
  )
}

export default BlogShare
